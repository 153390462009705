.ChatPluginContent :global {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 36px;

  .pluginIcon,
  .pluginName {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .pluginIcon span {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: rgba(var(--brand-rgb-1), 1);

    svg {
      width: 36px;
      height: 36px;

      // path {
      //   fill: #ffffff;
      // }
    }
  }

  .fillColor {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  .pluginName {
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(to right, #9466ff, #0022ff 100%);
    svg {
      margin-left: 4px;
      fill: rgba(var(--text-design-rgb-4), 1);
    }
  }

  .pluginDesc {
    display: flex;
    flex-flow: column;
    margin-top: 16px;
    padding: 0 0 0 8px;

    li {
      list-style: none;

      &:nth-child(n+6) {
        display: none;
      }
    }

    .pluginDescLink {
      color: rgba(var(--brand-rgb-1), 1);
      margin-left: 5px;
      cursor: pointer;
      display: inline-flex;
      flex-flow: row;
      justify-self: flex-start;
      align-items: center;

      &:hover {
        opacity: 0.85;
      }

      svg {
        margin-left: 4px;
      }      
    }

    .pluginDescItem {
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .pluginDescItemDot {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: rgba(0, 106, 255, 1);
      margin-right: 8px;
      margin-top: 9px;
      flex-shrink: 0;
    }

    .pluginDescItemText {
      display: inline-block;
      margin-right: 8px;
      line-height: 22px;
      overflow: hidden;
      color: rgba(var(--text-color-rgb-2), 1);
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;