.QuickRepliesModes :global {
  width: 100%;
  padding-bottom: 10px;
  
  .QuickReplies-Modes-inner {
    max-width: 884px;
    margin: 0 auto;
    padding: 0 15px;
    transition: opacity .3s;
    z-index: 100;
    display: flex;
    align-items: center;
    gap: 8px;
    .QuickReplies-Modes-title {
      width: 65px;
      flex-shrink: 0;
    }
    .QuickReplies-Modes-list {
      width: calc(100% - 65px);
    }

    @media only screen and (max-width: 992px) {
      .QuickReplies-Modes-list {
        width: calc(100% - 10px);
      }
    }
  }
}

.ScrollableTabsWithArrows :global {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
  
  .scroll-button.left {
    left: 0;
  }
  
  .scroll-button.right {
    right: 0;
  }

  .scroll-button.disabled {
    color: rgba(85, 85, 85, 0.5);
    cursor: not-allowed;
  }
  
  .scroll-button.disabled:hover {
    color: rgba(85, 85, 85, 0.5);
  }

  .menu-container {
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  .menu-scroll-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .menu-scroll-wrapper::-webkit-scrollbar {
    display: none;
  }

  .menu-items {
    display: inline-flex;
    gap: 6px;
    .menu-item {
      width: 110px;
      height: 30px;
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;
      .icon-bg {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: rgba(var(--brand-rgb-1), 0.1);
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
      }
  
      &:hover{
        border: 1px solid rgba(0, 106, 255, 1);
      }
  
      &.active {
        background: rgba(var(--brand-rgb-1), 1);
        color: #fff;
        .icon-bg {
          background: #fff;
        }
      }
    }
  }
}



[data-theme="light"] {
  .QuickRepliesModes :global {
    background-color: var(--background-color);
  }
}
[data-theme="dark"] {
  .QuickRepliesModes :global {
    background-color: #323232;
  }
}

@primary-color: #08979c;@font-size-base: 14px;