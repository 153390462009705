.ChatPluginHistory :global {
  margin-top: 8px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-left: 4px;
  height: 100%;

  .ChatPluginHistoryContent {
    height: 100%;
    width: 100%;
    transition: all .15s ease-in-out;
    overflow: hidden;

    &.fixed {
      position: absolute;
      top: 137px;
      height: calc(100% - 205px);
    }
  }

  .historyTitle {
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    padding-left: 4px;
    margin-bottom: 0;
    height: 35px;
    line-height: 35px;
  }

  .historyList {
    height: calc(100% - 35px);
    width: 100%;
  }

  .historyListItem {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 4px;
    border-radius: 4px;

    &.current span {
      color: rgba(var(--brand-rgb-1), 1) !important;
    }

    &:hover {
      background: rgba(var(--brand-rgb-1), 0.1);
    }

    span:first-child {
      color: rgba(var(--text-color-rgb-2), 0.7);
      max-width: calc(100% - 80px);
      height: 32px;
      line-height: 33px;
      overflow: hidden;
      text-overflow: ellipsis;      
      white-space: nowrap;
    }

    span:last-child {
      font-size: 13px;
      color: rgba(var(--text-color-rgb-2), 0.5);
    }
  }

  .ant-empty-description {
    color: rgb(var(--text-design-rgb-3));
  }

  .scrolling-bottom {
    .historyListItem:last-child {
      &:after {
        content: ' 已加载全部数据 ';
        position: absolute;
        padding: 10px 5px;
        left: 50%;
        transform: translate(-50%, 40px);
        color: rgba(var(--text-design-rgb-2), .15);
        width: 100%;
        text-align: center;
        padding: 8px 0 12px 0;
      }
    }
  }
}

[data-theme="light"] {
  .ChatPluginHistory :global {
    .ChatPluginHistoryContent.fixed {
      background: linear-gradient(to bottom, #E8F0FF 0%, #fff 30%, #fff 100%);
    }
  }
}

[data-theme="dark"] {
  .ChatPluginHistory :global {
    .ChatPluginHistoryContent.fixed {
      background: linear-gradient(to bottom, #2E2F32 0%, #181818 30%, #181818 100%);
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;