@import "@/components/common/var.less";

.LeftMenuBottom :global {
  box-sizing: border-box;
  position: absolute;
  bottom: 10px;
  left: 27%;

  .leftMenuBottom-Btn {
    height: 32px;
    width: 32px;
    line-height: 32px;
    margin-bottom: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(var(--bg-design-rgb-4), 0.3);
    }
  }

  .service-button {
    border-radius: 4px;
    color: var(--brand-1);
    svg {
      width: 20px;
      height: 20px;
    } 
  }

  .vip-button {
    border-radius: 4px;
    color: var(--brand-1);
    svg {
      width: 25px;
      height: 25px;
    }
  }

  .share-button {
    border-radius: 4px;
    svg {
      color: var(--brand-1);
      width: 25px;
      height: 25px;
    }
  }

  .message-button {
    border-radius: 4px;
    color: var(--brand-1);
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .special-button {
    border-radius: 4px;
    width: unset;
    height: unset;
    margin-bottom: 12px;
    margin-left: -13px;
    padding: 4px;
    img {
      width: 50px;
    }
  }

  .leftMenuBottom-newUser {
    line-height: 32px;
    margin-bottom: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
    z-index: 999;
  }
}

.newUserPopover :global {
  width: 250px;
  .ant-popover-inner {
    border-radius: 10px;
    box-shadow: 0 0 0.2px 0.2px rgba(96, 165, 250, 1) inset;
  }
  .ant-popover-arrow {
    display: none;
  }
}

.newUserContainer :global {
  .newuser-item {
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 205px;
    height: 48px;
    opacity: 1;
    border-radius: 5px;
    margin-bottom: 6px;

    .left {
      display: flex;
      align-items: center;
      gap: 5px;
      .title {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 17.38px;
      }
      .title.title-active {
        color: rgba(255, 34, 0, 1);
      }
      .title.title-disabled {
        color: #777777;
      }
      .subtitle {
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 14.48px;
        color: rgba(255, 255, 255, 1);
      }
    }

    .btn {
      width: 56px;
      height: 23px;
      opacity: 1;
      border-radius: 5px;
      background: rgba(255, 232, 205, 1);
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 14.48px;
      color: rgba(109, 21, 16, 1);
      text-align: center;
      line-height: 23px;
      cursor: pointer;
      &:hover {
        background: rgba(255, 196, 128, 1);
      }
    }

    .btn-disabled {
      background: rgba(229, 229, 229, 1);
      color: rgba(56, 56, 56, 1);
    }
  }

  .newuser-item-active {
    background: rgba(96, 165, 250, 1);
    box-shadow: 0px 0px 4px 2px rgba(96, 165, 250, 0.25);
  }

  .newuser-item-disabled {
    background: rgba(206, 206, 206, 1);
	  pointer-events: none; // 禁止点击
  }
}

.serviceModal :global {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      color: var(--brand-1);
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.specialModal :global {
  overflow: hidden !important;
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    position: relative;
  }
  .ant-modal-close-x {
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 20px;
    svg {
      color: rgba(238, 238, 238, 0.8);
    }
  }
}

.openMessageModal :global {
  overflow: hidden !important;
  .ant-modal-content {
    background-color: rgba(250, 250, 250, 1);
  }
  .ant-modal-header {
    border-bottom: none;
    background-color: rgba(250, 250, 250, 1);
    .ant-modal-title {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 23.17px;
      color: rgba(0, 0, 0, 1);
    }
  }

  .ant-modal-body {
    max-height: calc(80vh - 150px);
    overflow-y: auto;
    padding-top: 0;
    margin-bottom: 10px;
  }

  .message-container {
    padding: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(0, 0, 0, 1);

    img {
      display: block;
      margin: 0 auto;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 5px 10px 5px;
    .time {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 17.28px;
      color: rgba(206, 206, 206, 1);
    }
    .know-button {
      width: 80px;
      height: 28px;
      opacity: 1;
      border-radius: 5px;
      background: rgba(96, 165, 250, 1);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 28px;
      color: rgba(255, 255, 255, 1);
    }
  }
}


.messagesPopover :global {
  width: 300px;
  .ant-popover-inner {
    border-radius: 10px;
    box-shadow: 0 0 0.2px 0.2px rgba(96, 165, 250, 1) inset;

    .pop-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }


  .ant-popover-inner-content {
    padding: 5px 8px;
    max-height: 204px;
    overflow-y: auto;
    .message-list {
      .messsage-item {
        height: 40px;

        .message-info {
          display: flex;
          align-items: center;
          gap: 2px;
        }

        .message-title {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 17.38px;
          color: rgba(128, 128, 128, 1);
          white-space:nowrap;
          text-overflow: ellipsis;
          overflow:hidden;
          cursor: pointer;
        }
        .message-time {
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 15.93px;
          color: rgba(166, 166, 166, 1);
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;