@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url('./YouSheBiaoTiHei.ttf');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "AlibabaPuhuiTi-Regular";
  src: url('./AlibabaPuHuiTi-2-55-Regular.otf');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: "AlibabaPuhuiTi-Medium";
  src: url('./AlibabaPuHuiTi-2-55-Regular.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PingFang SC Regular";
  src: url('./PingFang-SC-Regular.ttf');
  font-weight: normal;
  font-style: normal;
} */
