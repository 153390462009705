@import "@/components/common/var.less";

.leftBubbleToolbar :global {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  color: rgba(var(--text-design-rgb-3), 1);
  padding: 0 6px;
  .ant-space {
    border-radius: 12px;
    padding: 4px 6px;
    &:hover {
      color: rgba(var(--text-design-rgb-1), 1);
      background-color: rgba(var(--bg-design-rgb-6), 0.35);
      cursor: pointer;
    }
  }
}

.rightBubbleToolbar :global {
  display: flex;
  align-self: flex-start;
  gap: 8px;
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  color: rgba(var(--text-design-rgb-3), 1);
  padding: 0 6px;
  .ant-space {
    border-radius: 12px;
    padding: 4px 6px;
    &:hover {
      color: rgba(var(--text-design-rgb-1), 1);
      background-color: rgba(var(--bg-design-rgb-6), 0.35);
      cursor: pointer;
    }
  }
}

.bubbleQuestionType :global {
  padding: 0 12px;

  span {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(var(--text-design-rgb-3), 0.80);
    background: rgba(var(--bg-design-rgb-5), 0.40);
    border-radius: 4px;
    padding: 0px 7px;
    margin-right: 5px;
  }
}

.AdditionalMessage :global {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  // border-radius: 8px;
  overflow: hidden;
  width: 100%;
  .processing {
    padding-left: 1em;
    width: 100%;
    .content-item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      svg {
        flex-shrink: 0;
      }
    }
    .content-item:not(:last-child){
      margin-bottom:10px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .content {
    color: #606060;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding-left: 1em;
    border-left: 0.2em rgba(96, 165, 250, 1) solid;
    letter-spacing: 0px;
    line-height: 21.72px;
    white-space: pre-wrap;
  }
}

[data-theme="light"] {
  .AdditionalMessage :global {
    color: #606060;
    background: rgba(223, 237, 254, 1);
    
    .ant-collapse-item {
      background: rgba(223, 237, 254, 1);
    }
    .content {
      color: #606060;
    }
  }
}

[data-theme="dark"] {
  .AdditionalMessage :global {
    color: rgba(var(--text-design-rgb-1), 0.85);
    background: rgba(223, 237, 254, 0.075);
    
    .ant-collapse-item {
      background: rgba(223, 237, 254, 0.075);
    }

    .content {
      color: rgba(var(--text-design-rgb-1), 0.85);
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;