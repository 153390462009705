[data-theme="dark"]:root {
  --gray-1: #fafafa;
  --gray-2: #f8f8f8;
  --gray-3: #f5f5f5;
  --gray-4: #eee;
  --gray-5: #ddd;
  --gray-6: #ccc;
  --gray-7: #999;
  --gray-8: #666;
  --gray-9: #111;
  --gray-rgb-1: 250, 250, 250;
  --gray-rgb-2: 248, 248, 248;
  --gray-rgb-3: 245, 245, 245;
  --gray-rgb-4: 238, 238, 238;
  --gray-rgb-5: 221, 221, 221;
  --gray-rgb-6: 204, 204, 204;
  --gray-rgb-7: 153, 153, 153;
  --gray-rgb-8: 102, 102, 102;
  --gray-rgb-9: 17, 17, 17;

  --border-design-opacity-1: 0.3; 
  --border-design-opacity-2: 0.2;

  --scrollbar-color: var(--gray-7);

  // 新样式颜色
  --main-background-color: #202020;
  --main-background-color-rgb: 32, 32, 32;
  --main-content-background-rgba: 20, 20, 20, 0.7;
  --active-color-rgb: 0, 106, 255;
  --active-color-rgba: 242, 242, 242, 0.1;
  --text-color-rgb-1: 96, 165, 250;
  --text-color-rgb-2: 250, 250, 250;
  --number-bg-rgb: 0, 84, 252;
  --paper-header-rgba: 242, 242, 242, 0.1;
  --paper-item-hover-rgba: 208, 226, 255, 0.3;
  --paper-item-active-rgba: 96, 165, 250, 0.2;
  --paper-item-selected-rgba: 96, 165, 250, 0.1;
  --empty-action-btn-rgba: 32, 32, 32;
  
  // 新登陆样式颜色
  --background-color: #202020;
  --sider-background-color-rgb: 18, 18, 18;
  --tabs-background-color-rgb: 32, 32, 32;
  --triangle-color-rgb: 32, 32, 32;
  --input-boder-color: 0, 106, 255;
  --input-background-color-rgb: 32, 32, 32;
  --message-background-color-rgb: 68, 150, 249;
  --opacity: 0.025;

  // 用户信息样式颜色
  --name-color: rgba(166, 166, 166, 1);
  --btn-color: rgba(229, 229, 229, 1);
  --btn-hover-color: rgba(96, 165, 250, 0.5);
}

[data-theme="dark"] {

  * {
    caret-color: rgba(var(--white-rgb), 1);
  }

  .Input::placeholder {
    color: rgba(var(--text-design-rgb-7, .5));
  }

  .sider {
    .sessionList .session {
      &.active,
      &:not(.selected):hover {
        background-color: rgba(var(--brand-rgb-1), 0.075);
      }
    }

    .sider-footer .ant-btn {
      color: rgba(var(--white-rgb), 1);
      background-color: rgba(var(--brand-rgb-1), 1);
      opacity: 0.88;

      sup {
        color: rgba(var(--white-rgb), 1);
      }

      &:hover {
        color: rgba(var(--white-rgb), 1);
        background-color: rgba(var(--brand-rgb-1), 1);
        opacity: 1;

        sup {
          color: rgba(var(--white-rgb), 1);
        }
      }
    }
  }

  .typing-cursor {
    background-color: #cccccc;
  }

  .DocumentLanding {
    opacity: 0.75;
  }
  
  .placeHolderContainer {
    opacity: 0.45;
  }

  .head-right .ant-segmented {
    &:hover {
      background-color: rgba(var(--white-rgb), 0.15);
    }
    .ant-segmented-group {
      .ant-segmented-item:hover {
        background-color: rgba(var(--bg-design-rgb-7), 0.3);
      }
      .ant-segmented-item-selected {
        background-color: rgba(var(--bg-design-rgb-8), 0.8);
      }
    }
  }

  [data-background-color=green] {
    background-color: rgba(var(--brand-rgb-1), 0.5);
  }

  .pdf-container {
    .pdf-container-left .pdf-viewer {
      background-color: rgba(var(--bg-design-rgb-8), .5);
    }
  }

  .paperMenu  {
    .ant-menu-item {
      &:hover {
        background-color: rgba(var(--bg-design-rgb-5), 0.25) !important;
      }
    }
    .ant-menu-submenu {
      &:hover {
        background-color: rgba(var(--bg-design-rgb-5), 0.25) !important;
      }
    }
  }

  .tippy-content {
    .mantine-Toolbar-root {
      border-color: rgba(var(--border-design-rgb-8), 1);
      box-shadow: 0px 4px 8px rgba(var(--border-design-rgb-9), 1), 0px 0px 1px rgba(var(--border-design-rgb-9), 1);
    }
    
    .mantine-Toolbar-root,
    .mantine-Toolbar-root .mantine-UnstyledButton-root {
      background-color: rgba(var(--bg-design-rgb-9), 1);
      color: rgba(var(--text-design-rgba-1, 1));
    }
    .mantine-Toolbar-root .mantine-UnstyledButton-root:hover {
      background-color: rgba(var(--bg-design-rgb-8), 0.6);
    }
  }

  .paperWindow .ant-popover .ant-popover-inner{
    .answer-content {
      background-color: rgba(var(--bg-design-rgb-9), 1);
      box-shadow: 0 3px 6px -4px rgba(var(--white-rgb), 0.12),
        0 6px 16px 0 rgba(var(--white-rgb), 0.08), 0 9px 28px 8px rgba(var(--white-rgb), 0.05);
    }
  }
  
  .questionTypeTabs.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .questionTypeTabs.ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom-color: rgba(var(--border-design-rgb-8), 1);
  }

  .questionTypeTabs.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .questionTypeTabs.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
  .questionTypeTabs.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .questionTypeTabs.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active  {
    border-bottom-color: rgba(var(--border-design-rgb-8), 1);
  }

  .userLayout .ant-card {
    box-shadow: 0px 1px 3px 0px rgba(var(--white-rgb), 0.65);
  }

  .authorization-modal {
    .qrcode-btn {
      background: rgba(var(--bg-design-rgb-5), 0.95);
    }
  }

  .chatHome-tabs-container {
    background-image: url("../assets/chat_home/tabs-bg-dark.png") !important;
  }


  .markmap {
    color: #fff;
  }

  .mm-toolbar {
    background-color: rgba(var(--gray-rgb-8));
    border-color: rgba(var(--gray-rgb-8));

    div[title="export"]:hover {
      color: #000;
    }
  }

  // 暗色模式下登陆保持亮色
  .login-wrapper {
    background: #fff;
    border-radius: 20px;
  }

  .input-code, .input-email {
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.85);

    &::placeholder {
      color: #b5b5b5;
    }
  }

  .input-phone {
    input, .ant-input-group-addon {
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.85);

      &::placeholder {
        color: #b5b5b5;
      }
    }
    }

  .login-footer, .login-message {
    color: rgba(0, 0, 0, 0.85);
  }

  // .tour-model-segmented-content {
  //   color: #ffffff !important;
  //   background-color: rgba(var(--white-rgb), 0.15);
  //   box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  // }
  
  .history-item {
    &.active {
      background: rgba(16, 16, 16, 1);
    }
  }

  .historySearchWrapperFixed, .historySearchWrapperFixedWithPreview {
    background-color: rgba(16, 16, 16, 1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  }

  .historySearchWrapperFixed, .historySearchWrapperFixedWithPreview {
    .ant-input-wrapper,
    .ant-input-wrapper input,
    .ant-input-suffix,
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      background-color: rgba(var(--main-background-color-rgb), 1);
    }
  }
  
  .paperList {
    .paper {
      border-bottom: 1px solid rgba(229, 229, 229, 0.1);
      
      &:first-child {
        border-top: 1px solid rgba(229, 229, 229, 0.1);
      }

    }
  }
}
