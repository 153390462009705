.historyAction {
    position: absolute;
    right: 10px;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.historyFilter :global {
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
    background: rgba(var(--brand-rgb-1), 0.1);
  }
}

@primary-color: #08979c;@font-size-base: 14px;