[data-theme="light"] {
  .ppt-bubble {
    .editorInput [contenteditable] {
      caret-color : rgba(var(--black-rgb), 1);
    }
  
    .editorHeader, .editorParagraph.h4 {
      background: rgba(var(--white-rgb), 1);
      // border: 1px solid rgba(var(--gray-rgb-4), 0.3);
      // border-radius: 12px;
    }
  }
}

[data-theme="dark"] {
  .ppt-bubble {
    .editorInput [contenteditable] {
      caret-color : rgba(var(--white-rgb), 1);
    }
  
    .editorHeader, .editorParagraph.h4 {
      background: rgba(var(--white-rgb), .03);
      // border: 1px solid rgba(var(--gray-rgb-5), 0.1);
      // border-radius: 12px;
    }
  }
}

.ppt-bubble {
  &.disabled {
    pointer-events: none;
  }
  .ppt-outline-title {
    padding: 10px 8px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
  }
  .ppt-outline-subtitle {
    margin: 15px 0 5px 8px;
    font-weight: bold;
  }
  .ppt-outline-editor {
    padding: 20px;
    border-radius: 8px;
    background-color: rgba(var(--brand-rgb-1), 0.2);
    border: 1px solid rgba(var(--brand-rgb-1), 0.3);
    width: calc(100% - 10px);
    margin-left: 8px;
  }
  .editorHeader {
    // &:hover {
    //   border: 1px solid rgba(var(--brand-rgb-1), 0.3);
    // }
    border-radius: 5px;
    background-color: transparent !important;
    &:hover,
    &:focus-within {
      // border: 1px solid rgba(var(--brand-rgb-1), 1);
      // border-radius: 12px;
      background-color: rgba(var(--white-rgb), 0.45) !important;
    }
  }
  
  .editorParagraph {
    // border-radius: 12px;

    &.h4 {
      background-color: transparent !important;
    }
  }
  .editorParagraphContent {
    background-color: transparent !important;
    border-radius: 5px;
    // &:hover {
    //   border: 1px solid rgba(var(--brand-rgb-1), 0.3);
    //   border-radius: 12px;
    // }
    // border: 1px solid transparent;
    &:hover,
    &:focus-within {
      // border-color: rgba(var(--brand-rgb-1), 1);
      // border-radius: 12px;
      background-color: rgba(var(--white-rgb), 0.45) !important;
    }
  }
  
  
  .ppt-buttons {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    align-items: start;
    justify-content: start;
  
    .ppt-button {
        border-radius: 30px;
        font-size: 12px;
        padding: 2px 16px;
        line-height: 18px;
        border-color: var(--brand-1);
        font-weight: 600;
        letter-spacing: 2px;
  
      svg {
        width: 14px;
        height: 14px;
        vertical-align: text-bottom;
        // margin: 0 4px 2px 0;
        opacity: 0.75;
      }
  
      span {
        margin-left: 2px;
      }
  
      &:hover {
        background-color: var(--brand-1);
        color: var(--white);
        box-shadow: 0px 0px 20px rgba(66, 129, 255, 0.3);
        -webkit-transition: all .5s ease-out;
        transition: all .5s ease-out;
      }
  
      & + button {
        margin-left: 5px;
      }
    }
  }
}

.history-ppt {
  .history-ppt-img {
    width: 506px;
    height: 285px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    margin: 12px 0;
    img {
      max-width: 100%;
      height: auto;
    }
    .image-eye {
      top: 50%;
      left: 50%;
      z-index: 3;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.history-ppt-loading {
  width: 506px;
  height: 285px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ppt-pages {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.ppt-pages-title {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18.82px;
  color: rgba(5, 115, 250, 1);
}
 
@primary-color: #08979c;@font-size-base: 14px;