@import "@/components/common/var.less";

.windowTitle :global {
  flex: 1;
  max-width: calc((100% - 455px) / 2);
  line-height: @line-height-lg;
  display: flex;
  align-items: center;
  padding: 0 @gutter;

  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .title-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title-back-icon {
      cursor: pointer;
      opacity: 0.7;
      padding: 7px 12px 7px 4px;
      display: inline-block;
      width: 32px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-right: 12px;
      
      &:hover {
        opacity: 1;
      }
    }

    .title-edit {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-left: 10px;
  
      input {
        margin-right: 8px;
        border-radius: 5px;
      }

      .title-action {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    
        > span {
          display: inline-block;
          width: 24px;
          height: 24px; 
          text-align: center;
          line-height: 24px;
          border-radius: 3px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
    
          &:hover {
            background-color: rgba(var(--bg-design-rgb-1), 0.1);
          }
        }
      }
    }

    > span {
      cursor: pointer;
      &[aria-label="clear"] {
        color: rgba(var(--text-design-rgb-3), 1);
        cursor: pointer;
        margin-left: 8px;
        z-index: 99;

        &:hover {
          color: var(--brand-1);
        }
      }
    }
  }
  .ant-popover {
    width: 100%;
    max-width: 380px;
    .ant-typography {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    width: calc(100% - 220px);
    max-width: unset;
    min-width: 100px;
  }
}

@primary-color: #08979c;@font-size-base: 14px;