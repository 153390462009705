[data-theme="light"]:root {
  --gray-0: #000;
  --gray-1: #111;
  --gray-2: #666;
  --gray-3: #999;
  --gray-4: #ccc;
  --gray-5: #ddd;
  --gray-6: #eee;
  --gray-7: #f5f5f5;
  --gray-8: #f8f8f8;
  --gray-9: #fafafa;
  --gray-rgb-0: 0, 0, 0;
  --gray-rgb-1: 17, 17, 17;
  --gray-rgb-2: 102, 102, 102;
  --gray-rgb-3: 153, 153, 153;
  --gray-rgb-4: 204, 204, 204;
  --gray-rgb-5: 221, 221, 221;
  --gray-rgb-6: 238, 238, 238;
  --gray-rgb-7: 245, 245, 245;
  --gray-rgb-8: 248, 248, 248;
  --gray-rgb-9: 250, 250, 250;

  --border-design-opacity-1: 0.9;  
  --border-design-opacity-2: 0.7;  

  --scrollbar-color: var(--gray-3);

   // 新样式颜色
   --main-background-color: #F2F2F7;
   --main-background-color-rgb: 242, 242, 247;
   --main-content-background-rgba: 255, 255, 255, 1;
   --active-color-rgb: 0, 106, 255;
   --active-color-rgba: 0, 106, 255, 0.25;
   --text-color-rgb-1: 128, 128, 128;
   --text-color-rgb-2: 56, 56, 56;
   --number-bg-rgb: 0, 84, 252;
   --paper-header-rgba: 242, 242, 242, 1;
   --paper-item-hover-rgba: 208, 226, 255, 0.7;
   --paper-item-active-rgba: 208, 226, 255, 0.2;
   --paper-item-selected-rgba: 208, 226, 255, 0.4;
   --empty-action-btn-rgba: 243, 244, 246;


  // 登陆新样式颜色
  --background-color: #fff;
  --background-color-rgb: 255, 255, 255;
  --sider-background-color-rgb: 250, 250, 248;
  --message-background-color-rgb: 242, 242, 242;
  --input-boder-color: 0, 106, 255;
  --input-background-color-rgb: 255, 255, 255;
  --tabs-background-color-rgb: 255, 255, 255;
  --triangle-color-rgb: 255, 255, 255;
  --opacity: 0.5;

  // 用户信息样式颜色
  --name-color: rgba(56, 56, 56, 1);
  --btn-color: rgba(128, 128, 128, 1);
  --btn-hover-color: rgba(96, 165, 250, 0.2);
}

[data-theme="light"] {

  * {
    caret-color: rgba(var(--black-rgb), 1);
  }

  .Input::placeholder {
    color: rgba(var(--text-design-rgb-3), 0.8);
  }

  .sider {
    .sessionList .session {
      &.active,
      &:not(.selected):hover {
        background-color: transparent;
      }
    }

    .sider-footer .ant-btn {
      color: rgba(var(--brand-rgb-1), 1);
      background-color: transparent;
      opacity: 1;

      sup {
        color: rgba(var(--brand-rgb-1), 1);
      }

      &:hover {
        color: rgba(var(--white-rgb), 1);
        background-color: rgba(var(--brand-rgb-1), 1);
        opacity: 1;

        sup {
          color: rgba(var(--white-rgb), 1);
        }
      }
    }
  }

  .typing-cursor {
    background-color: #333333;
  }

  .DocumentLanding {
    opacity: 0.9;
  }

  .placeHolderContainer {
    opacity: 0.7;
  }

  .head-right .ant-segmented {
    &:hover {
      background-color: rgba(var(--black-rgb), 0.15);
    }
    .ant-segmented-group {
      .ant-segmented-item:hover {
        background-color: rgba(var(--bg-design-rgb-9), 0.9);
      }
      .ant-segmented-item-selected {
        background-color: rgba(var(--bg-design-rgb-9), 1);
      }
    }
  }

  [data-background-color=green] {
    background-color: rgba(var(--brand-rgb-2), 0.5);
  }

  .pdf-container {
    .pdf-container-left .pdf-viewer {
      background-color: var(--background-color);
    }
  }

  .file-container {
    background-color: var(--background-color);
  }

  .empty-page .empty-page-container .empty-action-buttons .action-button.active {
    .action-button-icon {
      background: #fff;
    }
  }

  .paperMenu  {
    .ant-menu-item {
      &:hover {
        background-color: rgba(var(--bg-design-rgb-5), 0.7) !important;
      }
    }
    .ant-menu-submenu {
      &:hover {
        background-color: rgba(var(--bg-design-rgb-5), 0.7) !important;
      }
    }
  }

  .tippy-content {
    .mantine-Toolbar-root {
      border-color: rgba(var(--border-design-rgb-5), 1);
      box-shadow: 0px 4px 8px rgba(var(--border-design-rgb-9), 1), 0px 0px 1px rgba(var(--border-design-rgb-9), 1);
    }
    
    .mantine-Toolbar-root,
    .mantine-Toolbar-root .mantine-UnstyledButton-root {
      background-color: rgba(var(--bg-design-rgb-9), 1);
      color: rgba(var(--text-design-rgba-1, 1));
    }
    .mantine-Toolbar-root .mantine-UnstyledButton-root:hover {
      background-color: rgba(var(--bg-design-rgb-5), 0.75);
    }
  }

  .paperWindow .ant-popover .ant-popover-inner{
    .answer-content {
      background-color: rgba(var(--bg-design-rgb-9), 1);
      box-shadow: 0 3px 6px -4px rgba(var(--black-rgb), 0.12),
        0 6px 16px 0 rgba(var(--black-rgb), 0.08), 0 9px 28px 8px rgba(var(--black-rgb), 0.05);
    }
  }
  
  .questionTypeTabs.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .questionTypeTabs.ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom-color: rgba(var(--border-design-rgb-5), 1);
  }

  .questionTypeTabs.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .questionTypeTabs.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
  .questionTypeTabs.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .questionTypeTabs.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active  {
    border-bottom-color: rgba(var(--border-design-rgb-5), 1);
  }

  .userLayout .ant-card {
    box-shadow: 0px 1px 3px 0px rgba(var(--black-rgb), 0.12);
  }

  .authorization-modal {
    .qrcode-btn {
      background: rgba(var(--bg-design-rgb-8), 0.95);
    }
  }

  // .tour-model-segmented-content {
  //   background-color: #ffffff;
  //   box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  // }
  
  .history-item {
    &.active {
      background: rgba(232, 242, 255, 1);
    }
  }

  .historySearchWrapperFixed,  .historySearchWrapperFixedWithPreview {
    background-color: rgba(var(--white-rgb), 1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);    
  }
  
  .historySearchWrapperFixed,  .historySearchWrapperFixedWithPreview {
    .ant-input-wrapper,
    .ant-input-wrapper input,
    .ant-input-suffix,
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      background-color: rgba(var(--main-background-color-rgb), 1);
    }
  }

  .paperList {
    .paper {
      border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      
      &:first-child {
        border-top: 1px solid rgba(229, 229, 229, 0.5);

        &.selected {
          border-top: 1px solid #ffffff;
        }
      }

      &.selected {
        border-bottom: 1px solid #ffffff;
      }
    }
  }

  
}
