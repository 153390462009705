@import "@/components/common/var.less";

.chatWindow :global {
  margin: 14px 0 0 14px;
  width: calc(100% - 14px);
  height: calc(100% - 69px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 14px;

  @media screen and (min-width: 768px) {
    .PhraseBookContainer, .chatBottomContainer {
      transform: translate(28px, 0px); // 输入框偏移与message对齐
    }
  }
}

.chatWindowMain {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .chatWindow :global {
    flex: 1;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

@primary-color: #08979c;@font-size-base: 14px;