@import "@/components/common/var.less";
.MessageContainer {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: var(--background-color);

  :global {
    .scrolling-bottom {
      .messageList:last-child {
        &:after {
          content: '人家也是有底线的啦 @^_^@ ';
          position: absolute;
          // bottom: 0;
          left: 50%;
          transform: translate(-50%, 40px);
          color: rgba(var(--text-design-rgb-2), .35);
          width: 100%;
          text-align: center;
        }
      }
    }

    .messageList {
      margin-bottom: 30px;

      // 工具栏隐藏显示
      .Message.left:not(:nth-last-child(1)),
      .Message.right:not(:nth-last-child(1)) {
        
        &:hover .BubbleToolbar-btns {
          visibility: visible;
          margin-right: 10px;
        }
      }
    }

    .messageList.\/chat {
      .Message.left:not(:nth-last-child(2)),
      .Message.right:not(:nth-last-child(2)) {
        @media screen and (min-width: 768px) {
          .BubbleToolbar-btns {
            visibility: hidden;
          }
          &:hover .BubbleToolbar-btns {
            visibility: visible;
            margin-right: 10px;
          }
        }
      }
    }

    .messageList.\/document {
      .Message.left:not(:nth-last-child(1)),
      .Message.right:not(:nth-last-child(1)) {
        @media screen and (min-width: 768px) {
          .BubbleToolbar-btns {
            visibility: hidden;
          }
          &:hover .BubbleToolbar-btns {
            visibility: visible;
            margin-right: 10px;
          }
        }
      }
    }

    .messageLeftWrapper, .messageRightWrapper {
      max-width: 960px;
      margin: 0 auto;
      padding: 10px;
      display: flex;
      flex-direction: row;
      font-size: 15px;
      .avatar {
        margin: 0 10px;
      }
    }
    .messageLeftWrapper {
      .Bubble {
        background-color: transparent;
        padding: 12px 12px;

        &.text,
        &.typing {
          box-sizing: border-box;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          user-select: text;
        }
      }
  
      .BubbleToolbar {
        width: 100%;
        display: flex;
        flex-flow: row;

        .BubbleToolbar-btns {
          display: flex;
          gap: 6px;

          .pages {
           .page-item {
              width: 20px;
              height: 20px;
              cursor: pointer;
              &:hover {
                background: rgba(96, 165, 250, 0.1);
              }
              &.disabled {
                cursor: not-allowed;
                opacity: 0.3;
              }
           }
           .page-info {
              margin: 0 2px;
           }
          }

          .menu-item {
            display: flex;
            align-items: center;
            gap: 2px;
            cursor: pointer;
            padding: 0 4px;
            border-radius: 6px;
            font-size: 12px;
            &:hover {
              background: rgba(96, 165, 250, 0.1);
            }
            .menu-item-icon {
              height: 16px;
              width: 16px;
            }

            @container (max-width: 500px) {
              .menu-item-title {
                display: none;
              }
            }            
          }
        }
      }
    }
  
    .messageLeftWrapper:not(:first-child) {
      // border: @border-width solid rgba(var(--brand-rgb-1), 0.2);
      border-radius: 8px;
    }
  
    .messageRightWrapper {
      .Bubble {
        background-color: transparent !important;
        padding: 0 12px 12px;

        &.text,
        &.typing {
          box-sizing: border-box;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          user-select: text;
        }
      }
      .BubbleToolbar {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        .BubbleToolbar-btns {
          display: flex;
          gap: 6px;

          .menu-item {
            display: flex;
            align-items: center;
            gap: 2px;
            cursor: pointer;
            padding: 0 4px;
            border-radius: 6px;
            font-size: 12px;
            &:hover {
              background: rgba(96, 165, 250, 0.1);
            }
            .menu-item-icon {
              margin-top: 4px;
            }
          }
        }

        @container (max-width: 500px) {
          .BubbleToolbar-btns {
            .menu-item {
              .menu-item-title {
                display: none;
              }
            }
          }
        }
      }
    }
    .createNewSession {
      display: flex;
      justify-content: center;
      .newSessionBtn {
        width: 131px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background: rgba(223, 237, 254, 1);
        line-height: 36px;
        border-radius: 5px;
        cursor: pointer;
        .name {
          background-image: linear-gradient(to right, rgb(148, 102, 255), rgb(0, 34, 255) 50%, rgb(148, 102, 255) 100%);
          -webkit-background-clip: text;
          color: transparent;
        }
      }
      .newSessionBtn {
          transform: scale(1);
      }
  
      .newSessionBtn:active {
          transform: scale(0.97);
      }
    }
    }
  }

[data-theme="light"] {
  .MessageContainer {
    color: rgba(var(--text-design-rgb-1), 1);
    background-color: var(--background-color);

    :global {
      .messageLeftWrapper {
        .bubble-container {          
          container-type: inline-size;
        }
  
        .BubbleToolbar {
          .time {
            color: rgba(var(--text-design-rgb-4), 0.5);
          }
          &:hover {
            .time {
              color: rgba(var(--text-design-rgb-3), 0.85);
            }
          }
        }
      }
      .messageRightWrapper {
          .bubble-container {          
            container-type: inline-size;
          }    
          .bubble-container .text .file-list .file-bubble {
            background-color: rgba(var(--bg-design-rgb-4), 1)
          }
    
          .BubbleToolbar {
            .time {
              color: rgba(var(--text-design-rgb-4), 0.5);
            }
            &:hover {
              .time {
                color: rgba(var(--text-design-rgb-3), 0.85);
              }
            }
          }
        }
    }
  }
}

[data-theme="dark"] {
  .MessageContainer {
    color: rgba(var(--text-design-rgb-1), 0.85);
    background-color: rgba(var(--bg-design-rgb-6), 0.1);

    :global {
      .messageLeftWrapper {
        .bubble-container {          
          container-type: inline-size;
        }
        // background-color: rgba(var(--brand-rgb-2), 0.025);
  
        .BubbleToolbar {
          .time {
            color: rgba(var(--text-design-rgb-4), 0.15);
          }
          &:hover {
            .time {
              color: rgba(var(--text-design-rgb-4), 0.35);
            }
          }
        }
      }
      
      .messageRightWrapper {
        .bubble-container {          
          container-type: inline-size;
        }
        .bubble-container .text .file-list .file-bubble {
          background-color: rgba(var(--bg-design-rgb-8), 1)
        }
        
        .BubbleToolbar {
          .time {
            color: rgba(var(--text-design-rgb-4), 0.15);
          }
          &:hover {
            .time {
              color: rgba(var(--text-design-rgb-4), 0.35);
            }
          }
        }
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;