[data-theme="light"] {
  .outline-bubble {
    .editorInput [contenteditable] {
      caret-color : rgba(var(--black-rgb), 1);
    }
  
    .editorHeader, .editorParagraph.h4 {
      background: rgba(var(--white-rgb), 1);
      border: 1px solid rgba(var(--gray-rgb-4), 0.3);
      border-radius: 12px;
    }
  }
}

[data-theme="dark"] {
  .outline-bubble {
    .editorInput [contenteditable] {
      caret-color : rgba(var(--white-rgb), 1);
    }
  
    .editorHeader, .editorParagraph.h4 {
      background: rgba(var(--white-rgb), .03);
      border: 1px solid rgba(var(--gray-rgb-5), 0.1);
      border-radius: 12px;
    }
  }
}
.outline-bubble {
  &.disabled {
    pointer-events: none;
  }
  .editorHeader {
    &:hover {
      border: 1px solid rgba(var(--brand-rgb-1), 0.3);
    }
    &:focus-within {
      border: 1px solid rgba(var(--brand-rgb-1), 1);
    }
  }
  
  .editorParagraph {
    border-radius: 12px;
  }
  
  .editorParagraphContent {
    border: 1px solid transparent;
    
    &:hover {
      border: 1px solid rgba(var(--brand-rgb-1), 0.3);
      border-radius: 12px;
    }
    &:focus-within {
      border: 1px solid rgba(var(--brand-rgb-1), 1);
      border-radius: 12px;
    }
  }
  
  .outline-buttons {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    align-items: start;
    justify-content: start;
  
    .outline-button {
      border-radius: 30px;
          font-size: 12px;
          padding: 2px 16px;
          line-height: 18px;
          border-color: var(--brand-1);
          font-weight: 600;
          letter-spacing: 2px;
  
      svg {
        width: 14px;
        height: 14px;
        vertical-align: text-bottom;
        margin: 0 4px 2px 0;
        opacity: 0.75;
      }
  
      &:hover {
        background-color: var(--brand-1);
        color: var(--white);
        box-shadow: 0px 0px 20px rgba(66, 129, 255, 0.3);
        -webkit-transition: all .5s ease-out;
        transition: all .5s ease-out;
      }
  
      & + button {
        margin-left: 5px;
      }
    }
  }
}


@primary-color: #08979c;@font-size-base: 14px;