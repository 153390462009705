.historyList :global {
  width: 100%;
  margin-top: 0px;
}

.historyListWrapper :global {
  display: block;
  position: relative;
}

.historySection :global {
  margin-bottom: 32px;
  h3 {
    font-family: "Microsoft Yahei";
    font-size: 18px;
    color: rgba(var(--gray-rgb-2), 1);
    font-weight: bold;
    margin-bottom: 8px;
  }
}
@primary-color: #08979c;@font-size-base: 14px;