.customSider :global {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  height: 100%;
  z-index: 99;
  padding-top: 16px;
  background-color: rgba(var( --main-content-background-rgba));
  transition: all .28s ease-in-out; 
  
  .custom-sider-toggle-close {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-50%);
    filter: drop-shadow(6px 2px 6px rgba(0, 106, 255, 0.2));

    .custom-sider-toggle {
      position: absolute;
      top: 50%;
      cursor: pointer;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      width: 20px;
      height: 80px;
      opacity: 1;
      -webkit-clip-path: polygon(0 1%, 100% 17%, 100% 87%, 0% 100%);
      clip-path: polygon(0 1%, 100% 17%, 100% 87%, 0% 100%);
      z-index: 3;
      
      svg {
        width: 12px;
      }
  
      &:hover {
        opacity: 1;
      }
    }
  }

  .custom-sider-toggle-open {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateX(-50%);
    filter: drop-shadow(-4px 5px 10px rgba(0, 106, 255, 0.2));

    .custom-sider-toggle {  
      cursor: pointer;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      width: 20px;
      height: 80px;
      opacity: 1;
      // box-shadow: 0px 2px 10px  rgba(0, 106, 255, 0.2);
      -webkit-clip-path: polygon(0 10%, 100% 1%, 100% 100%, 1% 87%);
      clip-path: polygon(0 10%, 100% 1%, 100% 100%, 1% 87%);
      z-index: 3;
      
      svg {
        width: 12px;
        color: rgba(0, 106, 255, 1);
      }
  
      &:hover {
        opacity: 1;
      }
    }
  }
}

[data-theme="light"] {
  .customSider :global {

    .custom-sider-toggle {
      background: rgba(255, 255, 255, 1);
      svg {
        color: rgba(0, 106, 255, 1);
      }
    }
  }
}
[data-theme="dark"] {
  .customSider :global {

    .custom-sider-toggle {
      background: rgba(0, 106, 255, 1);
      svg path {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;