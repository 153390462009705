.RefineDiffMessage :global {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  width: 100%;
  .header {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .diffText {
    white-space: pre-wrap;
    .diff-added {
      color: var(--status-success);
    }
     
    .diff-removed {
      text-decoration: line-through;
      color: var(--status-error);
    }
     
    .diff-unchanged {
      color: #000;
    }
  }
}

[data-theme="light"] {
  .RefineDiffMessage :global {
    color: #606060;
    background: rgba(223, 237, 254, 1);
    
    .ant-collapse-item {
      background: rgba(223, 237, 254, 1);
    }
    .content {
      color: #606060;
    }
  }
}

[data-theme="dark"] {
  .RefineDiffMessage :global {
    color: rgba(var(--text-design-rgb-1), 0.85);
    background: rgba(223, 237, 254, 0.075);
    
    .ant-collapse-item {
      background: rgba(223, 237, 254, 0.075);
    }

    .content {
      color: rgba(var(--text-design-rgb-1), 0.85);
    }
    .diffText {
      .diff-unchanged {
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}
@primary-color: #08979c;@font-size-base: 14px;