.chatSider :global {
  height: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  overflow: hidden;
}

[data-theme="light"] {
  .chatSider :global {
    background-image: url("../../../assets/docintrobg.png");
    background-size: 100% 100%;

  }
}

[data-theme="dark"] {
  .chatSider :global {
    background: linear-gradient(180deg, rgba(217, 231, 255, 0.2) 0%, rgba(217, 231, 255, 0) 34%, rgba(0, 0, 0, 0) 100%);
    
  }
}

@primary-color: #08979c;@font-size-base: 14px;