.selectorContainer :global {
  min-width: 150px;
  height: 24px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid rgba(var(--border-design-rgb-2), 0.5);
  margin-left: 2px;
  margin-bottom: 3px;

  .selector-label {
    margin-left: 3px;
    color: rgba(var(--text-design-rgb-2), 0.6);
  }
}

@primary-color: #08979c;@font-size-base: 14px;