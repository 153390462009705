@import "@/components/common/var.less";
.chatHome :global {
  width: calc(100% - 14px);
  height: calc(100% - 69px);
  border-radius: 5px 5px 0 0;
  background-color: rgba(var(--main-content-background-rgba));
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 5% 5% 0 5%;

  // PC端
  .chatHome-tabs-container {
    max-width: 1520px;
    height: 156px;
    padding: 0 20px;
    margin: 0 auto;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/chat_home/tabs-bg.jpg");
    background-size: cover;
  }

  .chatHome-tabs-wrapper {
    width: 100%;
    height: 100%;
    max-width: 1050px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .chatHome-tabs-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      transform: scale(1.2);
    }

    &.active {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: rgba(var(--tabs-background-color-rgb), 1);
      
      .tab-icon {
        svg {
          width: 50px;
          height: 50px;
        }
      }

      &:after {
        content: "";
        width: 0px;
        height: 0px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid rgba(var(--triangle-color-rgb), 1);
        position: absolute;
        bottom: -28px;

      }

      &:hover {
        transform: none;
      }
    }

    .tab-icon {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(var(--tabs-background-color-rgb), 1);
    }

    .tab-name {
      font-size: 16px;
      font-weight: 700;
      color: var(--text-design-rgb-1);
      margin-top: 10px;
    }
  }
  .chatHome-tabs-content {
    overflow: hidden;
    padding: 55px 10px 0 10px;
  }

  .mobile-chatHome-tabs-content {
    overflow: hidden;
    padding: 12px 15px 0 15px;
  }

  // mobile样式
  .mobile-chatHome-tabs-wrapper {
    width: 100%;
    height: 55px;
    background-image: url("../../../assets/chat_home/tabs-bg.jpg");
    background-size: cover;

    .mobile-chatHome-tabs {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .mobile-chatHome-tabs-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .mobile-tab-icon {
          width: 22px;
          height: 22px;
          margin-bottom: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(var(--tabs-background-color-rgb), 1);
            border-radius: 4px;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .mobile-tab-name {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 14px;
          color: rgba(56, 56, 56, 0.6);
          &.active {
            color: rgba(255, 255, 255, 1);
          }
        }

        &.active {
          &:after {
            content: "";
            width: 0px;
            height: 0px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid rgba(var(--triangle-color-rgb), 1);
            position: absolute;
            bottom: -6px;
    
          }
        }
      }
    }
  }
}

.questionTypeCard :global {
  width: 100%;
  height: 100%;
  max-width: 1050px;
  overflow: hidden;
  margin: 0 auto;

  .questionTypeCardItem{
    height: 100px;
    width: 222px;
    padding: 0px 8px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border: 2px solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1)) !important;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);

    &:hover {
      .cover {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 2;
        svg {
          width: 20px;
          height: 20px;
        }
        span {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 2px;
          line-height: 21.72px;
          color: rgba(96, 165, 250, 1);
          text-align: left;
          vertical-align: top;
        }
      }
      .card-actions {
        visibility: visible;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 3;
      }
    }
  }

  .question-type-item-content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    gap: 10px;

    .question-type-item-avatar-wrapper {
      min-width: 55px;
      display: flex;
      align-items: center;
      min-height: 100%;
      .question-type-item-avatar {
        width: 44px;
        height: 44px;
        background-color: rgba(var(--brand-rgb-1), 0.5);
        border-radius: 10px;
        position: relative;
        .avatar-bg {
          width: 34px;
          height: 34px;
          background-color: #ffffff;
          border-radius: 17px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      svg {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .question-type-item-info {
      margin-top: 8px;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .question-type-item-title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;
        line-height: 23.17px;
        color: rgba(var(--gray-rgb-0), 1);
      }
    }
  }

  .card-badge {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    text-align: center;
    color: #ffffff;
    background: #ff2f00;
    border-radius: 10px 0 10px 0;
    font-size: 11px;
    z-index: 10;
  }

  .cover, .card-actions {
    visibility: hidden;
  }

  .simpleChatCardItem {
    height: 100px;
    width: 222px;
    padding: 8px 8px !important;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--brand-rgb-1), 0.3);
    border: @border-width solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1)) !important;
    .simpleChat {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      svg {
        width: 28px;
        height: 28px;
      }
    }
    .simpleChatText {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 23.17px;
      color: var(--brand-1);
      text-align: left;
      vertical-align: top;
    }

    &:hover {
      background-color: rgba(var(--brand-rgb-1), 1);

      .simpleChatText {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

// mobile端插件样式
.mobileQuestionTypeCard :global {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;

  .questionTypeCardItem{
    height: 80px;
    width: 160px;
    padding: 0px 4px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border: 1px solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1)) !important;
    box-sizing: border-box;
  }

  .question-type-item-content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    gap: 5px;

    .question-type-item-avatar-wrapper {
      min-width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      .question-type-item-avatar {
        width: 32px;
        height: 32px;
        background-color: rgba(var(--brand-rgb-1), 0.5);
        border-radius: 10px;
        position: relative;
        .avatar-bg {
          width: 26px;
          height: 26px;
          background-color: #ffffff;
          border-radius: 13px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        svg {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .question-type-item-info {
      margin-top: 8px;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .question-type-item-title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 20.27px;
      }
    }
  }

  .card-badge {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    text-align: center;
    color: #ffffff;
    background: #ff2f00;
    border-radius: 10px 0 10px 0;
    font-size: 11px;
    z-index: 10;
  }

  .simpleChatCardItem {
    height: 80px;
    width: 160px;
    padding: 4px 4px !important;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--brand-rgb-1), 0.3);
    border: @border-width solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1)) !important;
    .simpleChat {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .simpleChatText {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 20.27px;
      text-align: left;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 992px) {
  .chatHome {
    margin-top: 0px;
    width: 100%;
    height: 100%;
  }
}

@primary-color: #08979c;@font-size-base: 14px;