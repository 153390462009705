.withLeftMenu :global {
  left: 70px !important;
  width: calc(100vw - 70px) !important;
}

.historyOverlay :global {
  position: fixed;
  z-index: 900;
  width: 100vw;
  left: 0;
  top: 0;
  height: 100vh;
  backdrop-filter: blur(6px);
  display: flex;

  .historyOverlayClose {
    position: fixed;
    right: 16px;
    top: 16px;
    z-index: 9998;
  }

  .historySearchWithClose {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    
    .historySearchClose {
      margin-right: 6px;
    }
  }

  .historySearchClose,
  .historyOverlayClose {
    > span {
      width: 32px;
      height: 32px;
      display: inline-block;
      text-align: center;
      line-height: 32px;
      background: rgba(var(--gray-rgb-3), 0.7);
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }
  }

  .historyOverlayContent {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .historyContainer {
    height: 100%;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
  }

  .historyOverlayContentWithPreview {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 0;
  }
  
  .historyContainerWithPreview {
    height: 100%;
    width: calc(100% - 32px);
    margin: 0 auto;
  }

  .historyPreviewContainer {
    height: calc(100% - 191px);
    position: absolute;
    width: calc(50%);
    right: 0;
  } 

  .historyHeader {
    height: 100px;
    text-align: center;
    font-size: 32px;
    color: rgba(var(--gray-rgb-2), 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Microsoft Yahei";
    margin-bottom: 20px;

    &.historyHeaderFixed {
      visibility: hidden;
      height: 58px;
    }
  }

  .historySearchWrapper {
    &.historySearchWrapperFixed {
      position: fixed;
      top: 0px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      backdrop-filter: blur(5px);
      z-index: 992;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);

      .ant-input-wrapper,
      .ant-input-wrapper input,
      .ant-input-suffix,
      .ant-input-group-addon,
      .ant-input-affix-wrapper {
        background-color: rgba(var(--main-background-color-rgb), 1);
      }
    }
  }

  .historySearchInner {
    width: 100%;
    height: 100%;
    max-width: 1040px;
    margin: 0 auto;
  }

  .historySearch {
    height: 42px;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0 10px 0;
    flex-grow: 1;

    .ant-input-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-input-group-addon {
      border: none !important;
      display: inline-flex      ;
      align-items: center;
      justify-content: center;
      width: 50px;
      flex-shrink: 0;
    }
    .ant-input-affix-wrapper {
      margin-top: 1px;
    }
    .ant-input-affix-wrapper,
    & + .ant-input-affix-wrapper {
      border: none !important;
      height: 40px;
      line-height: 40px;
      input:active,
      input:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .ant-empty-description {
    color: rgb(var(--text-design-rgb-3));
  }

  .scrolling-bottom {
    .history-section:last-child .history-item:last-child {
      &:after {
        content: '人家也是有底线的啦 @^_^@ ';
        position: absolute;
        padding: 10px 5px;
        left: 50%;
        transform: translate(-50%, 40px);
        color: rgba(var(--text-design-rgb-2), .35);
        width: 100%;
        text-align: center;
        padding: 32px 0 26px 0;
      }
    }
  }

  .historyPreviewWrapper {
    height: 100%;
    display: flex;
    flex-flow: row;
    gap: 16px;
    justify-content: center;
    position: relative;

    &.historyPreviewWrapperFixed {
      .HistoryPreview {
        margin-top: 0;
      }

      .historyPreviewContainer {
        height: calc(100% - 14px);
      }

      .previewAction {
        bottom: 16px;
        z-index: 99;
      }
    }
  }

  .historyListWrapper {
    width: 100%;
    
    .history-scrollbar > div:nth-child(3) {
      top: 35px !important;
    }
  }

  .historyPreviewWrapperWithPreview {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 16px;
    min-height: 100%;
    height: fit-content;

    .historyListWrapper {
      width: calc(50% - 16px);
    }
  }

}

[data-theme="light"] {
  .historyOverlay :global {
    background-color: rgba(var(--gray-rgb-6), 0.85);
    border-left: 1px solid rgba(var(--border-design-rgb-9), 1);

    .historySearch {
      background-color: rgba(var(--background-color-rgb), 1);
      .ant-input-group-addon {
        background-color: transparent;
      }
    }
  }
}

[data-theme="dark"] {
  .historyOverlay :global {
    background-color: rgba(var(--main-background-color-rgb), 0.85);
    border-left: 1px solid rgba(var(--border-design-rgb-8), 0.5);

    .historySearch {
      background-color: rgba(var(--main-background-color-rgb), 1);

      .ant-input-wrapper,
      .ant-input-wrapper input,
      .ant-input-suffix,
      .ant-input-group-addon,
      .ant-input-affix-wrapper {
        background-color: rgba(51, 51, 51, 1);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .historyOverlay :global {
    height: calc(var(--vh) * 100 - 61px) !important;
    .historySearchClose {
      display: none;
    }

    .historySearch,
    .historyContainer {
      padding: 0 10px;
    }

    .historyOverlayContent {
      padding: 0 0px;
    }

    .historyPreviewWrapper {
      height: calc(var(--vh)  * 100 - 243px);
    }
    .historyPreviewWrapperFixed {
      height: calc(var(--vh)  * 100 - 139px);
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;