@import "@/components/common/var.less";

.mobileWindowTitle :global {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 @gutter;

  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .title-text {
      // margin-right: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title-edit {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-left: 10px;
  
      input {
        margin-right: 3px;
        border-radius: 5px;
        padding: 2px;
      }

      .title-action {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    
        > span {
          display: inline-block;
          width: 18px;
          height: 18px; 
          text-align: center;
          line-height: 18px;
          border-radius: 3px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
    
          &:hover {
            background-color: rgba(var(--bg-design-rgb-1), 0.1);
          }
        }
      }
    }

    .title-back-icon {
      cursor: pointer;
      opacity: 0.7;
      padding: 7px 12px 7px 4px;
      display: inline-block;
      width: 32px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-right: 12px;
      
      &:hover {
        opacity: 1;
      }
    }

    .save-button {
      display: flex;
      gap: 8px;
      align-items: center;

      .anticon-save {
        color: rgba(var(--text-design-rgb-2), 1);
        height: 18px;
        line-height: 18px;
      }
      .save-info {
        min-width: 74px;
        font-size: 12px;
        color: rgba(var(--text-design-rgb-3), 1);
        > span {
          margin-right: 4px;
        }
      }
    }

    > span {
      cursor: pointer;
      &[aria-label="clear"] {
        color: rgba(var(--text-design-rgb-3), 1);
        cursor: pointer;
        margin-left: 8px;
        z-index: 99;

        &:hover {
          color: var(--brand-1);
        }
      }
    }
  }
  .ant-popover {
    width: 100%;
    max-width: 380px;
    .ant-typography {
      margin-bottom: 0;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;