.HistoryPreview :global {
  position: relative;
  width: 100%;
  height: calc(100% - 48px);
  margin: 35px 20px 0 0;
  border-radius: 10px;
  background: linear-gradient(to Bottom, rgba(223, 237, 254, 1) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 100%);
  box-shadow: 1px 1px 6px 2px rgba(166, 166, 166, 0.1);
  padding: 50px 0px 10px 0px;

  .previewTitle {
    position: absolute;
    height: 50px;
    line-height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    padding-left: 22px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--text-design-rgb-1), 1);
    // border-bottom: 1px solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1));
  }

  .MessageContainer {
    height: 100% !important;
    background-color: transparent;
    
    .message-scrollbar {
      height: 100% !important;
    }
  }

  .previewAction {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 8px;
    width: 100%;
    left: 0;
    padding: 36px 0 12px 0;
    border-radius: 0 0 5px 5px;

    .closeBtn,
    .previewBtn {

      height: 36px;
      line-height: 36px;
      text-align: center;
      padding: 0 36px;
      cursor: pointer;
      position: relative;
      border-radius: 5px;
    }

    .closeBtn {
      color: rgba(0, 106, 255, 1);
      border: 1px solid rgba(0, 106, 255, 1);
      margin-right: 36px;

      &:hover {
        background-color: rgba(0, 106, 255, 0.15);
      }
    }
    .previewBtn {
      color: #fff;
      background: rgba(0, 106, 255, 1);

      &:hover {
        background-color: rgba(0, 106, 255, 0.75);
      }
    }
    .previewBtn::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: inherit;
      border-color: #fff;
      border-radius: inherit;
      transform: translate(-50%, -50%);
      opacity: 0;
      content: ' ';
    }
    .previewBtn:active::before {
      opacity: 0.1;
    }
  }
}

[data-theme="light"] {
  .HistoryPreview :global {
    background: linear-gradient(to Bottom, rgba(223, 237, 254, 1) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 100%);


    .previewAction {
      background: linear-gradient(180deg, transparent 0%, rgba(255, 255, 255, 0.65) 10%, #fff 100%);
    }
  }
}
[data-theme="dark"] {
  .HistoryPreview :global {
    background: linear-gradient(to Bottom, rgba(217, 231, 255, 0.2) 0%, rgba(217, 231, 255, 0) 34%, rgba(0, 0, 0, 0) 100%);
    
    .previewAction {
      background: linear-gradient(to Bottom, transparent 0%, rgb(32 32 32 / 80%) 34%, #202020 100%);
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;