@import "@/components/common/var.less";

.leftMenu :global {
  width: 70px;
  min-width: 70px;
  height: 100%;
  // background-color: var(--main-background-color);
  background-color: var(--background-color);
  // margin-right: 14px;
  position: relative;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  position: relative;


  .leftMenu-logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
    }
  }

  .leftMenu-main {
    display: table-cell;
    height: 86%;
    .leftMenu-main-item {
      height: 52px;
      width: 61px;
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: rgba(var(--brand-rgb-9), 1);
      font-weight: 600;


      &.active {
        border-radius: 5px;
        color: rgba(var(--active-color-rgb));
        background-color: rgba(var(--active-color-rgba));
      }
      
      &:hover {
        color: rgba(var(--active-color-rgb));
      }
    }
  }
}

.leftMenu:after :global {
  content: ' ';
  position: absolute;
  width: 30px;
  height: 55px;
  z-index: 1;
  top: 0;
  left: 55px;
  pointer-events: none;
}

[data-theme="light"] {
  .leftMenu :global {
    background-color: var(--background-color);
  }
  
  .leftMenu:after :global {
    background-color: rgba(var(--white-rgb), 1);
  }
}

[data-theme="dark"] {
  .leftMenu :global {
    background-color: rgba(16, 16, 16, 1);
  }

  .leftMenu:after :global {
    background-color: rgba(16, 16, 16, 1);;
  }
}

@primary-color: #08979c;@font-size-base: 14px;