@import "@/components/common/var.less";

.windowBd :global {
  flex: 1;
  overflow: hidden;

  .window-body-container {
    // background-color: rgba(var(--bg-design-rgb-9), 1);
    background-color: var(--main-background-color);
    position: relative;
    height: 100%;
    display: flex;
    overflow: hidden;

    .absolute {
      position: absolute;
    }
    .full-width {
      left: 0;
    }
    .zero-width {
      left: -300px;
    }
    .window-body-inner {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }     

    @media only screen and (max-width: 768px) {
      .full-width {
        width: 100%;
        flex: 1;
      }
      .zero-width {
        width: 0;
        display: none;
      }
    }

    .sider-toggle {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateX(100%) translateY(-100%);
      // border-radius: 0 20px 20px 0;
      // padding: 8px 0;
      border-color: var(--btn-primary-border-color);
      background: var(--btn-primary-bg);
      background-origin: border-box;
      color: var(--btn-primary-color);
      cursor: pointer;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      opacity: 0.92;
      width: 12px;
      height: 38px;
      border-radius: 0 8px 8px 0;
      z-index: 3;
      
      &:before,
      &:after {
        position: absolute;
        content: ' '
      }
    
      &:hover {
        background: var(--btn-primary-bg);
        opacity: 1;
      }
    }
    
    // span[aria-label="arrow-left"],
    // span[aria-label="arrow-right"] {
    //   padding: 0 6px;
    //   font-size: @font-size-xs;
    //   color: var(--white);
    //   z-index: 999;
    //   border: none;
    //   // margin-top: 10px;
    // }
  }

  span[aria-label="menu-unfold"] {
    padding: 0 15px;
    font-size: @font-size-lg;
    line-height: 48px;
    position: fixed;
    top: 150px;
    left: 0;
    background-color: var(--brand-3);
    z-index: 999;
    border: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
    cursor: pointer;
  }
}

//copyRight
.copyRight {
  width: 100%;
  height: 46px;
  color: rgba(var(--text-design-rgb-4), 1);
  padding: @gutter;
  justify-content: center;

  a:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    & {
      display: none !important;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;