.ChatPluginAction :global {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 8px;
  height: 120px;

  .actionHeader,
  .actionFooter {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .actionHeader {
    height: 70px;
    border-bottom: 1px solid rgba(var(--message-background-color-rgb), 1);
  }

  .newSessionBtn {
    width: 131px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: rgba(223, 237, 254, 1);
    line-height: 36px;
    border-radius: 5px;
    cursor: pointer;
    .name {
      background-clip: text;
      background-image: linear-gradient(to right, rgb(148, 102, 255), rgb(0, 34, 255) 50%, rgb(148, 102, 255) 100%);
      color: transparent;
    }
  }
  .newSessionBtn {
      transform: scale(1);
  }

  .newSessionBtn:active {
      transform: scale(0.97);
  }

  .actionFooter span {
    height: 40px;
    line-height: 42px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration-line: underline;
    color: var(--brand-1);
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

}

@primary-color: #08979c;@font-size-base: 14px;